import { render } from "./UserManagement.vue?vue&type=template&id=6a8ba730"
import script from "./UserManagement.vue?vue&type=script&lang=js"
export * from "./UserManagement.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6a8ba730"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6a8ba730', script)) {
    api.reload('6a8ba730', script)
  }
  
  module.hot.accept("./UserManagement.vue?vue&type=template&id=6a8ba730", () => {
    api.rerender('6a8ba730', render)
  })

}

script.__file = "src/components/user-management/UserManagement.vue"

export default script